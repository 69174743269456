.hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero::before {
    content: '';
    background: url('./../assets/home/hero.jpeg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    margin: auto;
    padding: 2rem 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.hero img {
    width: 400px;
    height: 400px;
    margin: auto;
    border-radius: 24px 0 24px 0;
}

.hero .col-1 {
    margin: auto;
}

.hero .content p:first-child {
    margin-top: 1rem;
    font-size: 3rem;
}

.hero .content p:nth-child(2) {
    font-size: 3.5rem;
    font-family: 700;
    margin-bottom: 2rem;
}

.hero .content p:nth-child(3) {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.hero .button {
    margin: 2rem 0;
    width: 300px;
    background-color: transparent;
    border: 0.4px solid #fff;
    color: #fff;
}

.hero .button:hover {
    background-color: var(--secondary-dark);
    border: 1px solid var(--secondary-dark);
    color: #333;
}

@media screen and (max-width:940px) {
    .hero img{
        display: none;
    }
    .hero .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hero .content .col-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hero .content p:nth-child(1) {
        font-size: 3rem;
    }
    .hero .content p:nth-child(2) {
        font-size: 2.5rem;
        font-family: 400;
        margin: 1rem;
    }
    .hero .content p:nth-child(3) {
        font-size: 1.5rem;
        margin: 1rem
    }
    
    .hero .button {
        margin: 2rem;
    }
}

@media screen and (max-width:480px) {

    .hero .content {
        padding: 0;
    }

    .hero .button {
        width: 200px;
    }
    .hero .button {
       font-size: 0.9rem;
       margin-top: 4rem;
    }
}