.work {
    width: 100%;
    background-color: #f8f8f8;
}

.work .container {
    width: 1240px;
    margin: 0 auto;
    padding: 6rem 0rem 6rem 0;
}

.work h2 {
    font-size: 3rem;
    text-align: center;
}

.work .container .content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    margin: 5rem 0 4rem 0;
}


.work .card {
    width: 395px;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgb(0,0,0,.2);
    padding: 1rem;
}

.work img {
    width: 360px;
    height: 230px;
    margin-top: -2rem;
    border-radius: 8px;
}

.work p {
    font-size: 1.6rem;
    text-align: center;
    color: #010101;
}

.work .card p {
    color: #787878;
    font-size: 1.1rem;
    margin: 12px;
}

.work  .card span {
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
}

.work .card p:nth-child(3) {
    font-weight: 400;
}



@media screen and (max-width: 940px) {
    .work .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .work .container .content {
        grid-template-columns: 1fr;
        grid-gap: 2.4rem;
    }

    .work .card {
        max-width: 100%;
        margin: auto;
    }
}

@media screen and (max-width:480px) {

    .work .card {
        width: 335px;
    }
    

    .work img {
        width: 300px;
        height: 190px;
    }

}












