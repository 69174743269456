.about {
    margin: auto;
    padding: 3rem 1rem 5rem 1rem;
    width: 100%;
}

.about .container {
    width: 1240px;
    margin: auto;
}

.about .container .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about h2 {
    font-size: 3rem;
    text-align: center;
}

.about img {
    width: 500px;
}

.about p {
    font-size: 1.2rem;
    margin-top: 3rem;
    color: #555;
}

.about .col-1 {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding: 3rem;
    color: #555;
}

.about .col-1 .step p {
    margin-top: 0.2rem;
    font-size: 1rem;
    color: #7D7C7C;
    line-height: 1.5rem;
    font-weight: 100;
}

.about .col-1 .step p:first-of-type {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 100;
}



.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding: 3rem;
    color: #555;
}


.about .col-2 .step p {
    margin-top: 0.2rem;
}


.about .col-2 .step p:first-of-type {
    margin-top: 2rem;
    font-size: 1rem;
}



.about .col-2 .step p span {
    font-weight: 600;
    color: #010101
}

.about .col-2 .step p .post {
    color: #010101
}



@media screen and (max-width: 940px) {

    .about {
        padding: 3rem 1rem 2rem 1rem;
    }

    .about .container {
        grid-template-columns: 1fr;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about .container .content {
        grid-template-columns: 1fr;
        
    }

    .about .container img {
        padding-top: 1rem;
        width: 60vw;
        margin: auto;
    }

    .about .container .col-2 {
        margin-top: 2rem;
        padding: auto;
    }

}
