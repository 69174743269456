body.active-modal {
    overflow-y: hidden;
}

.modal, .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    width: 80%;
    height:80%;
    min-width: 300px;
}

.modal-content .video_frame {
    width: 100%;
    height:90%;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

@media screen and (max-width:940px) {
    .modal-content {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #f1f1f1;
        padding: 14px 28px;
        border-radius: 3px;
        width: 96%;
        height:60%;
        min-width: 300px;
    }

    .modal-content .video_frame {
        width: 100%;
        height:80%;
    }
}